import thunk from 'redux-thunk';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';

import account from 'services/account/getAccount';
import accountDetails from 'components/transaction-view/api';
import accounts from 'services/accounts/getAccounts';
import auth from 'services/auth/login';
import form from 'services/form/getForm';
import formCount from 'services/form/getFormCount';
import fund from 'services/fund/getFund';
import user from 'services/user/getUser';
import userDetails from 'components/crud-user/api';
import users from 'components/user-table/api';

const reducers = combineReducers({
  account,
  accountDetails,
  accounts,
  auth,
  form,
  formCount,
  fund,
  user,
  userDetails,
  users,
});

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: ['auth'],
};

const rootReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store, {});
export default store;
