import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import fetch from 'node-fetch';
import { toast } from 'react-toastify';
import { set as setUser } from 'services/user/getUser';

export const initialState = {
  success: null,
  error: null,
  token: null,
  isAdmin: null,
  instance: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      const { secret, isAdmin, instance } = payload;
      return {
        ...state,
        error: null,
        success: true,
        token: secret,
        isAdmin,
        instance,
      };
    },
    loginFailure: (state, { payload }) => {
      state.success = false;
      state.error = payload;
    },
    clear: () => {
      return initialState;
    },
  },
});

export default loginSlice.reducer;
export const { clear } = loginSlice.actions;

export const loginSuccess = (res, noRedirect) => async dispatch => {
  const { isAdmin, user, scopes, id } = res;
  console.log({ res });

  dispatch(loginSlice.actions.loginSuccess(res));
  dispatch(
    setUser({
      ...user,
      scopes,
      id,
    })
  );
  if (noRedirect) return;
  if (isAdmin === 'true' || isAdmin === true) {
    Router.push('/admin/reports');
  } else {
    Router.push('/client/accounts');
  }
};

const loginFailure = error => dispatch => {
  toast.error(error, {
    position: 'top-center',
  });

  return dispatch(loginSlice.actions.loginFailure(error));
};

export const login = ({
  username,
  password,
  verificationCode,
}) => async dispatch => {
  try {
    const response = await fetch(`/api/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password, verificationCode }),
    });

    const res = await response.json();

    if (response.ok) {
      return dispatch(loginSuccess(res));
    } else {
      if (res.error) {
        // show the descriptive error from api
        return dispatch(loginFailure(res.error));
      }
    }

    return dispatch(loginFailure('Could not log you in'));
  } catch (error) {
    return dispatch(loginFailure('Login failed'));
  }
};

export const checkAuth = async ({ username, password }) => {
  try {
    const response = await fetch(`/api/check_auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });

    const { credsAreValid, mFA, ...res } = await response.json();

    if (response.ok) {
      return {
        credsAreValid,
        mFA,
      };
    }

    if (res.error) {
      toast.error(res.error, {
        position: 'top-center',
      });
    } else {
      toast.error('Unsuccessful login, please try again later', {
        position: 'top-center',
      });
    }

    return {
      credsAreValid: false,
      failedLogin: res.failedLogin,
    };
  } catch (error) {
    return {
      credsAreValid: false,
    };
  }
};
