import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';
import { differenceInSeconds } from 'date-fns';

export const initialState = {
  submitted: 0,
  received: 0,
  executed: 0,
  confirmed: 0,
  lastPoll: null,
};

const slice = createSlice({
  name: 'formCount',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const getFormCount = (token, overRide = false) => async (
  dispatch,
  getState
) => {
  const { formCount } = getState();
  if (
    (formCount['lastPoll'] &&
      differenceInSeconds(new Date(), formCount['lastPoll']) < 90 &&
      !overRide) ||
    !token
  ) {
    return;
  }
  //TODO: update when manual edit
  const options = {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };
  const [
    submittedRes,
    receivedRes,
    executedRes,
    confirmedRes,
  ] = await Promise.all([
    fetch(
      `${process.env.BASE_PATH}/api/forms?status=submitted&fields=count`,
      options
    ),
    fetch(
      `${process.env.BASE_PATH}/api/forms?status=received&fields=count`,
      options
    ),
    fetch(
      `${process.env.BASE_PATH}/api/forms?status=executed&fields=count`,
      options
    ),
    fetch(
      `${process.env.BASE_PATH}/api/forms?status=confirmed&fields=count`,
      options
    ),
  ]);

  const [submitted, received, executed, confirmed] = await Promise.all([
    submittedRes.json(),
    receivedRes.json(),
    executedRes.json(),
    confirmedRes.json(),
  ]).catch(() => [undefined, undefined, undefined, undefined]);
  if (submitted && received && executed && confirmed) {
    dispatch(
      set({
        submitted: submitted.count,
        received: received.count,
        executed: executed.count,
        confirmed: confirmed.count,
        lastPoll: new Date(),
      })
    );
  }
};
