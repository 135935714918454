import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';

export const initialState = {};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state[payload.id] = payload;
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const getForm = (token, id) => async (dispatch, getState) => {
  const { form } = getState();
  // return early and don't fetch if id already in store
  if (form[id] && 1 == 2) return; // temp disable because state changes aren't being registered form data becomes stale

  const res = await fetch(`${process.env.BASE_PATH}/api/forms/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  const data = await res.json();

  dispatch(set({ ...data, id }));
};
