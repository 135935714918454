import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';
import fetch from 'node-fetch';
import { toast } from 'react-toastify';

export const initialState = {};

const slice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state[payload.updateId] = payload;
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const fetchInitialState = ({ token, updateId }) => async (
  dispatch,
  getState
) => {
  const { userDetails } = getState();
  if (userDetails[updateId]) return;

  const params = {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await fetch(
      `${process.env.BASE_PATH}/api/users/${updateId}`,
      params
    );
    const data = await res.json();
    dispatch(
      set({
        updateId,
        ...data,
      })
    );
  } catch (error) {
    return null;
  }
};

export const _delete = async ({ updateId, token }) => {
  try {
    const res = await fetch(`${process.env.BASE_PATH}/api/users/${updateId}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
      },
    });

    if (res.ok) {
      toast('User succesfully deleted');
      Router.push('/admin/users', '/admin/users');
    } else {
      toast.error('There was an error processing your request');
    }
  } catch (error) {
    toast.error('There was an error processing your request');
  }
};

export const update = async ({ userProps, updateId, token }) => {
  try {
    const res = await fetch(`${process.env.BASE_PATH}/api/users/${updateId}`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userProps),
    });

    if (res.ok) {
      toast('Successfully updated user');
    } else {
      toast('There was an error processing your request');
    }
  } catch (error) {
    toast('There was an error processing your request');
  }
};

export const create = async ({ token, userProps }) => {
  try {
    const res = await fetch(`${process.env.BASE_PATH}/api/users`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...userProps, notify: true }),
    });
    if (res.ok) {
      toast('Successfully created user');
    } else {
      toast('There was an issue when submitting your request');
    }

    Router.push('/admin/users', '/admin/users');
  } catch (error) {
    console.error(error);
    toast('There was an issue when submitting your request');
  }
};

export const editPermissions = async ({ userProps, updateId, token }) => {
  try {
    const res = await fetch(`${process.env.BASE_PATH}/api/users/${updateId}`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userProps),
    });

    if (!res.ok) {
      toast('There was an error processing your request');
    }
  } catch (error) {
    console.error(error);
    toast('There was an error processing your request');
  }
};

export const handleReset = async ({ email, token }) => {
  try {
    const res = await fetch(`${process.env.BASE_PATH}/api/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (res.ok) {
      toast('A password reset has been sent to your email');
    } else {
      toast('There was an error processing your request');
    }
  } catch (error) {
    console.error(error);
    toast('There was an error processing your request');
  }
};

export const getActivitiesByUser = async (userId, token) => {
  try {
    const res = await fetch(
      `${process.env.BASE_PATH}/api/activities/user/${userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );

    if (!res.ok) {
      return [];
    }

    const { data } = await res.json();
    return data;
  } catch (error) {
    console.error(error);
    toast('There was an error processing your request');
  }
};
