import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';
import { getIdFromRef } from 'services/utils';
import _ from 'lodash';

export const initialState = [];

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return _.unionBy([...state], payload, 'id');
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear } = slice.actions;

export default slice.reducer;

export const getAccounts = token => async dispatch => {
  const res = await fetch(`${process.env.BASE_PATH}/api/accounts`, {
    headers: {
      Authorization: token,
    },
  });
  const { data } = await res.json();
  const accounts = data.map(el => {
    return { ...el.data, id: getIdFromRef(el) };
  });
  dispatch(set(accounts));
};
