import React, { useState, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { loadingContext } from 'services/ui';
import { toast } from 'react-toastify';

const LoadingState = ({ children }) => {
  const [barHeight, setBarHeight] = useState(0);

  const enableBar = () => setBarHeight(3);
  const disableBar = () => setBarHeight(0);

  const loadingBarRef = useRef(null);

  const withLoader = asyncFunc => {
    return async (...args) => {
      try {
        if (loadingBarRef.current) {
          console.log(loadingBarRef);
          enableBar();
          loadingBarRef.current.continuousStart();
        }

        const res = await asyncFunc(...args);

        if (loadingBarRef.current) {
          loadingBarRef.current.complete();
        }
        disableBar();

        return res;
      } catch (error) {
        console.error(error);
        toast.error(
          'Fetching this resource failed. Please reload the page, or try again later',
          {
            position: 'top-center',
          }
        );

        if (loadingBarRef.current) {
          loadingBarRef.current.complete();
        }
        disableBar();
      }
    };
  };

  return (
    <>
      <LoadingBar
        height={barHeight}
        color="rgba(19,124,53,0.95)"
        ref={loadingBarRef}
      />
      <loadingContext.Provider
        value={{
          startLoading: () => loadingBarRef.current.continuousStart(),
          completeLoading: () => loadingBarRef.current.complete(),
          withLoader,
        }}
      >
        {children}
      </loadingContext.Provider>
    </>
  );
};

export default LoadingState;
