import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';
import { Grommet } from 'grommet';
import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import 'styles/css/ReactToastify.css';
import Normalize from 'styles/normalize';
import theme from 'styles/theme/index.json';
import {
  initialState as secretsInitialState,
  loginSuccess,
} from '../services/auth/login';
import store, { persistor } from '../store';
import InitialStateWrapper from './_getInitialState';
import LoadingState from './_loadingState';
import DebugContext from '../contexts/debug';

if (process.env.BUILD_MODE === 'production') {
  Sentry.init({
    dsn: `${process.env.SENTRY_DSN}`,
  });
}

theme.mode = 'dark';

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    // sets any development instance to call the instance's api instead of the alias
    const BASE_PATH = (() => {
      if (
        process.env.BUILD_MODE == 'production' &&
        ctx.req &&
        ctx.req.headers
      ) {
        return process.env.BASE_PATH;
        // return ctx.req.headers['x-now-deployment-url'];
      }
      return process.env.BASE_PATH;
    })();
    const secrets = (() => {
      const object =
        ctx.req && ctx.req.headers.cookie
          ? ctx.req.headers.cookie
              .split(';')
              .map(function(c) {
                return c
                  .trim()
                  .split('=')
                  .map(decodeURIComponent);
              })
              .reduce(function(a, b) {
                try {
                  a[b[0]] = JSON.parse(b[1]);
                } catch (e) {
                  a[b[0]] = b[1];
                }
                return a;
              }, {})
          : null;
      return object && object['persist:root'] && object['persist:root'].auth
        ? JSON.parse(object['persist:root'].auth)
        : secretsInitialState;
    })();
    if (ctx.req) {
      const path = ctx.req.url.split('/').filter(e => e != '')[0];
      if (
        (!secrets || !secrets.token) &&
        (path == 'client' || path == 'admin')
      ) {
        ctx.res.writeHead(301, { Location: '/login' });
        ctx.res.end();
      }
      if (!secrets.isAdmin && path === 'admin') {
        ctx.res.writeHead(301, { Location: '/client/accounts' });
        ctx.res.end();
      }
    }

    try {
      // Call the page's `getInitialProps` if it exists. Don't `await` it yet,
      // because we'd rather `await` them together concurrently.
      const pagePropsTask = Component.getInitialProps
        ? Component.getInitialProps({ ...ctx, ...secrets })
        : {};

      // Both tasks are running concurrently, now await them both.
      const [pageProps] = await Promise.all([pagePropsTask]);
      if (pageProps) {
        pageProps.BASE_PATH = BASE_PATH;
        return {
          pageProps,
        };
      } else {
        return { BASE_PATH, ...pageProps };
      }
    } catch (e) {
      console.warn(e);
      return {};
    }
  }

  async componentDidMount() {
    if (window.Cypress) {
      window.appReady = true;
    }
    try {
      const credentials = window.localStorage.getItem('ws_creds');
      if (credentials) {
        console.log('Using stored credentials');
        await store.dispatch(
          loginSuccess(JSON.parse(credentials), window.appReady)
        );
        window.localStorage.removeItem('ws_creds');
      }
    } catch (err) {
      console.log('window undefined in did mount');
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <DebugContext debugEnabled={process.env.BUILD_MODE !== 'production'}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <Grommet theme={theme}>
                <ToastContainer position="top-center" />
                <Normalize />
                <InitialStateWrapper>
                  <LoadingState>
                    <Component {...pageProps} />
                  </LoadingState>
                </InitialStateWrapper>
              </Grommet>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </DebugContext>
    );
  }
}

export default MyApp;
