import { createSlice } from '@reduxjs/toolkit';
import fetch from 'node-fetch';
import { toast } from 'react-toastify';

export const initialState = [];

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return payload;
    },
    append: (state, { payload }) => {
      const [...rest] = payload;
      return [...state, ...rest];
    },
    clear: () => {
      return initialState;
    },
  },
});

export const { set, clear, append } = slice.actions;

export const getUsers = (token, lastIndex) => async dispatch => {
  try {
    const res = await fetch(
      `${process.env.BASE_PATH}/api/users?lastIndex=${lastIndex || 0}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    const { data, after } = await res.json();
    const users = data;

    if (lastIndex === undefined) {
      dispatch(set(users));
    } else {
      data.shift();
      dispatch(append(data)); // remove the duplicate
      return after ? false : true; // if there are no more results to return
    }
  } catch (error) {
    console.error({ error });
    toast.error('Something went wrong fetching list of users', {
      position: 'top-center',
    });
  }
};

export default slice.reducer;
