import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUser } from '../services/user/getUser';

export default ({ children }) => {
  const dispatch = useDispatch();
  const { token, instance } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (token && instance) {
      (async () => {
        await dispatch(getUser(token, instance));
      })();
    }
  }, []);

  return children;
};
